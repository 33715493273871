const state = {
  models: [
    {
      img: "https://media.discordapp.net/attachments/1080058777971212370/1118830759621435502/Nisa_Pic_02.png?width=1648&height=1236",
      name: "Nisa Zhang",
      nickname: "nisa_zhang",
      bg_color_about: "bg-gradient-to-tl from-purple-500 to-blue-300",
      about_img:
        "https://i.seadn.io/gcs/files/9f9207b675692ea9e68ed958b7c5eb16.png?auto=format&dpr=1&w=1000",
      about_1:
        "Meet Nisa Zhang, a 22-years-old woman who is a delightful blend of Thai and Chinese heritage. Born on September 12, 2000, in Bangkok, Thailand, Nisa has grown up embracing both cultures and cherishing the unique traditions and customs that come with them.",
      about_2:
        "At 5'6' tall and weighing 115 pounds, Nisa has a slender and graceful figure that reflects her love for fitness and healthy living. She enjoys trying new workout routines, from yoga to kickboxing, and also loves to explore the great outdoors through hiking and camping.",
      age: "Age : 22 years old",
      blood_type: "Blood Type : AB",
      height: "Hight : 170 cm. , Weight : 52 kg.",
      Chest: "Chest : 36” , Waist : 24” , Hips : 35”",
      followers_img:
        "https://media.discordapp.net/attachments/1080058777971212370/1118830298872959036/Nisa_Pic_01.png?width=1564&height=1236",
      followers: "50K",
      text_followers:
        "Nisa is also a gifted linguist, fluent in Thai, Chinese, English, and French. Her ability to speak multiple languages has opened doors for her to work as a translator and interpreter for international organizations and events.💖🎶🌈☔️🍕🍷⚽️✈️🎡📷",
      twitter: "",
      reddit: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/nisa.zhang/",
      facebook: "",
      tiktok: "",
      img_show:
        "https://media.discordapp.net/attachments/1080058777971212370/1118830298872959036/Nisa_Pic_01.png?width=1564&height=1236",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112630379757719602/00103.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1113802277891883069/Nisa_Cream_02.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112630510942945300/00108.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1113802304840273930/Nisa_Cream_04.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112631920157786143/00090-2919982846.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1113807236859428925/00239.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112631175421378700/00001-3782904770.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112630379757719602/00103.png",
      ],
      contract_address: "0x03bc5f2474FD603e8dD0c2Fbb8Fa5281CD879b00",
      token_id: "0",
      ipfs: "https://ipfs.io/ipfs/bafkreicfy4hdrsdfgoxzpxdhxjg6o5w2ry3rb7hh34gpt3xn6w6tgzxt2e",
      token_standard: "ERC-721",
      chain: "BEP-20",
      chain_id: "1 (0x1)",
      coin_name: "Nisa Zhang ( Nisa )",
      description_nft: "Nisa Zhang verify by VirtualKOL.com",
    },
    {
      img: "https://cdn1.psfk.com/wp-content/uploads/2022/08/62a200766353648d1152ce8d_howvirtualinfluencersusetwitter.jpeg",
      name: "MIQUELA SOUSA",
      nickname: "miquela_sousa",
      bg_color_about: "bg-gradient-to-tl from-red-500 to-gray-400",
      about_1:
        "Miquela Sousa, popularly known as Lil Miquela, is 19, a global pop star, one of TIME Magazine's '25 Most Influential People on the Internet' — and she’s not human. She’s a robot.",
      about_2:
        "Miquela is a musician, change-seeker, and style visionary who began as the laboratory creation of the nefarious corporation Cain Intelligence. Her now-managers (and robo-family), Los Angeles-based tech startup Brud, believed she deserved a chance at a better life, and reprogrammed her to have human-level consciousness.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231243448070264/Screenshot_2023-06-18_180203.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231173105385573/Screenshot_2023-06-18_180253.png",
      followers: "2.8M ",
      text_followers:
        "#BlackLivesMatter 🤖 19-year-old Robot living in LA 💖 Check out my vids 🤗⬇️✨",
      twitter: "https://twitter.com/lilmiquela",
      reddit: "",
      youtube:
        "https://www.youtube.com/channel/UCWeHb_SrtJbrT8VD-_QQpRA/featured",
      instagram: "https://www.instagram.com/lilmiquela/",
      facebook: "https://www.facebook.com/lilmiquela/",
      tiktok: "https://www.tiktok.com/@lilmiquela",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231273609310259/Screenshot_2023-06-18_180133.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231291930030170/Screenshot_2023-06-18_180121.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231274217480242/Screenshot_2023-06-18_180152.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231273932263496/Screenshot_2023-06-18_180143.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231243980746863/Screenshot_2023-06-18_180222.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231243695521833/Screenshot_2023-06-18_180213.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231208866037760/Screenshot_2023-06-18_180230.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231209197371453/Screenshot_2023-06-18_180237.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120231174036533258/Screenshot_2023-06-18_180311.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://assets.propmark.com.br/uploads/2021/06/alok-magalu-Copia.png",
      name: "LU OF MAGALU",
      nickname: "magazineluiza",
      bg_color_about: "bg-gradient-to-tl from-yellow-600 to-gray-400",
      about_1:
        "Lu first came to life on YouTube in August 2009 to promote iBlogTV on behalf of Magazine Luiza ('Magalu'). Since 2009, Lu has used her massive rapidly growing social media accounts to feature unboxing videos, product reviews, and software tips on behalf of the company, one of the largest Brazilian retail companies. Magalu saw $552M in profit in 2019.",
      about_2:
        "Lu can be seen on the navigation bar of Magalu's website, Magalu's Android and iPhone apps, and commands a massive following, particularly on Facebook, making her the most visible virtual human in the world.",
      about_img:
        "https://media.discordapp.net/attachments/1080058777971212370/1120298787001344092/Screenshot_2023-06-18_174342.png?width=988&height=1236",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120301880573186109/images.jpeg",
      followers: "6.3M",
      text_followers:
        "Lu do Magalu 💙 🙎🏻‍♀️ Influenciadora Virtual 3D 👩🏻‍💻 Especialista Digital do #Magalu 📸 Criadora de Conteúdo",
      twitter: "https://twitter.com/magazineluiza",
      reddit: "",
      youtube: "https://www.youtube.com/channel/UCeaQ72LrN6K3f9a8JkFV98w",
      instagram: "https://www.instagram.com/magazineluiza/",
      facebook: "https://www.facebook.com/magazineluiza/",
      tiktok: "https://www.tiktok.com/@magalu",
      img_show:
        "https://media.discordapp.net/attachments/1080058777971212370/1120298819960180786/Screenshot_2023-06-18_174228.png?width=990&height=1236",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298819150680114/Screenshot_2023-06-18_174155.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298819486240849/Screenshot_2023-06-18_174214.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298786053423124/Screenshot_2023-06-18_174318.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298786468671488/Screenshot_2023-06-18_174333.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298680306651257/Screenshot_2023-06-18_174606.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298754545827891/Screenshot_2023-06-18_174353.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298755086889080/Screenshot_2023-06-18_174427.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298754826834000/Screenshot_2023-06-18_174418.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298721607942145/Screenshot_2023-06-18_174526.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120298679903989790/Screenshot_2023-06-18_174555.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://images.nintendolife.com/ca702338af47c/hatsune-miku.900x.jpg",
      name: "HATSUNE MIKU",
      nickname: "hatsune_miku",
      bg_color_about: "bg-gradient-to-tl from-green-900 to-gray-400",
      about_1:
        "Hatsune Miku is a Japanese music sensation, a 16 year-old blue-haired girl with a unique voice and prodigious energy. What makes her special is that she is not a human singer, but a virtual persona who uses a computer-generated voice and 3D graphics to perform on stage. With a repertoire entirely provided by individual creators, she is the first truly crowd-created virtual talent. But being virtual doesn't stop her from filling up concert halls, or teaming up with major artists like Lady Gaga and Pharrell Williams!",
      about_2:
        "In 2016, she returned for a series of live concerts in her native country of Japan, before heading to North America for even more dates, to once again meet thousands of fans eager to see her 'in person'! Sourced from Crypton Future Media, creators of Hatsune Miku",
      about_img:
        "https://media.discordapp.net/attachments/1080058777971212370/1120366818985521172/Screenshot_2023-06-18_180813.png?width=874&height=1236",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120366934542790768/Screenshot_2023-06-18_180756.png",
      followers: "140K",
      text_followers:
        "初音ミク 公式 クリプトン・フューチャー・メディアのバーチャル・シンガー「初音ミク」公式アカウントです🎤 初音ミクたちピアプロキャラクターズの最新グッズやイベントなど、様々な情報をお届け📣 ▼公式ブログはこちら",
      twitter: "https://twitter.com/cfm_miku",
      reddit: "",
      youtube: "https://www.youtube.com/channel/UCJwGWV914kBlV4dKRn7AEFA",
      instagram: "https://www.instagram.com/cfm_miku_official/",
      facebook: "https://www.facebook.com/HatsuneMikuOfficialPage/",
      tiktok: "https://www.tiktok.com/@hatsunemiku0831",
      img_show:
        "https://media.discordapp.net/attachments/1080058777971212370/1120367019628437516/Screenshot_2023-06-18_180634.png?width=938&height=1236",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120367020073029704/Screenshot_2023-06-18_180644.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120367018617618462/Screenshot_2023-06-18_180620.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120367018244317294/Screenshot_2023-06-18_180600.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120366970093719552/Screenshot_2023-06-18_180730.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120366969439391744/Screenshot_2023-06-18_180707.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120366969766555699/Screenshot_2023-06-18_180721.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120366934823796847/Screenshot_2023-06-18_180804.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120366819790831636/Screenshot_2023-06-18_180835.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120366819354611822/Screenshot_2023-06-18_180823.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120370910264827964/Screenshot_2023-06-18_181929.png",
      name: "GUGGIMON",
      nickname: "guggimon",
      bg_color_about: "bg-gradient-to-tl from-pink-400 to-gray-400",
      about_1:
        "Guggimon has stated he's a fashion horror artist & mixtape producer with obsessions: handbags, axes, designer toys, Billie Eilish, & The Shining 🐰🏠",
      about_2:
        "Superplastic is the world’s premier creator of animated synthetic celebrities, designer toys, and apparel. Home to Janky & Guggimon. Superplastic was created by artist and entrepreneur Paul Budnitz (Kidrobot, Ello, Budnitz Bicycles). Legendary toy artist Huck Gee oversees art and production. A bunch of other brilliant people of all colors, shapes, and sizes also work here. We’re located in Burlington, Vermont, because it’s awesome. Sourced from Superplastic.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371133196284020/Screenshot_2023-06-18_181132.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371033518645370/Screenshot_2023-06-18_181827.png",
      followers: "1.4M",
      text_followers:
        "Usually sexier than I am scary. Usually ☠️ Fashion horror artist & @janky destroyer",
      twitter: "https://twitter.com/guggimon",
      reddit: "",
      youtube: "https://www.youtube.com/channel/UC4-vlwEqx2g9sFui-nTo-9g",
      instagram: "https://www.instagram.com/guggimon/",
      facebook: "https://www.facebook.com/Superplastic/",
      tiktok: "https://www.tiktok.com/@reallyjanky",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371187533488158/Screenshot_2023-06-18_181112.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371187906773013/Screenshot_2023-06-18_181122.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120370910952689704/Screenshot_2023-06-18_182011.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371187218919464/Screenshot_2023-06-18_181103.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371134156783736/Screenshot_2023-06-18_181156.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371133590540329/Screenshot_2023-06-18_181142.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371105367072900/Screenshot_2023-06-18_181214.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371105815855235/Screenshot_2023-06-18_181223.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371104905695312/Screenshot_2023-06-18_181206.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371064573263965/Screenshot_2023-06-18_181747.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120371063847649400/Screenshot_2023-06-18_181235.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120540924389367939/Screenshot_2023-06-18_184216.png",
      name: "IMMA",
      nickname: "imma",
      bg_color_about: "bg-gradient-to-tl from-yellow-700 to-gray-400",
      about_1:
        "imma (meaning “now” in Japanese) is the first virtual human and model in Japan, produced by Aww Inc.",
      about_2:
        "Rocking the infamous bubble gum pink bob and ultra fine fashion, she was selected for “New 100 Talent To Watch” by Japan Economics Entertainment, and has appeared in numerous headlines from fashion to business magazines, TV and online, as well as being cast for top brands such as Porsche Japan, IKEA, Dior, Puma, Nike, Valentino, Amazon, Calvin Klein, Valentino...the list goes on.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120541030371033239/Screenshot_2023-06-18_184010.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120540886976180314/Screenshot_2023-06-18_184251.png",
      followers: "400K",
      text_followers:
        "Digital creator I'm a virtual girl 'imma' in Tokyo🇯🇵🏳️‍🌈 Love Fashion👚Art🎨 Film🎬 and more🧠 Plz check out my linktree👇👇",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/imma.gram/",
      facebook: "",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120541031415414844/Screenshot_2023-06-18_184040.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120541030828216390/Screenshot_2023-06-18_184024.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120541029980967013/Screenshot_2023-06-18_184001.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120540978097442936/Screenshot_2023-06-18_184114.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120540977120169984/Screenshot_2023-06-18_184050.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120540977522802788/Screenshot_2023-06-18_184101.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120540948200431676/Screenshot_2023-06-18_184126.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120540923865083914/Screenshot_2023-06-18_184203.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120540887794069516/Screenshot_2023-06-18_184330.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120542817215840306/Screenshot_2023-06-18_184608.png",
      name: "SHADU GRAM",
      nickname: "shadu_gram",
      bg_color_about: "bg-gradient-to-tl from-pink-300 to-gray-400",
      about_1:
        "Shudu is a digital supermodel and fashion queen in her mid-to-late 20s hailing from South Africa. Shudu has made great strides to advocate for the virtual human race, having worked with Cosmopolitan, Vogue, Balmain, Smart Car, and more. Shudu spends her time posing in premier, mystical shoot locations to display eye-popping fashion pieces. Shudu has many virtual friends and always looks her best.",
      about_2:
        "Shudu is the creation of Cameron James, founder of The Diigitals Agency in Weymouth, Dorset, England and creator of 7 virtual humans including Koffi, Brenn, Galaxia, Dagny, and more.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542816519606282/Screenshot_2023-06-18_184547.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542668158664724/Screenshot_2023-06-18_185139.png",
      followers: "239K",
      text_followers: "Shudu The World’s First Digital Supermodel",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "",
      facebook: "https://www.facebook.com/DigitalShudu/",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542817668837467/Screenshot_2023-06-18_184901.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542816863531091/Screenshot_2023-06-18_184559.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542789084659743/Screenshot_2023-06-18_185001.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542788832997396/Screenshot_2023-06-18_184945.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542788459696139/Screenshot_2023-06-18_184934.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542735460483112/Screenshot_2023-06-18_185104.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542788166107146/Screenshot_2023-06-18_184915.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542763407114331/Screenshot_2023-06-18_185051.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542763168059453/Screenshot_2023-06-18_185037.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542762903810128/Screenshot_2023-06-18_185027.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120542735728914492/Screenshot_2023-06-18_185115.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120546503522517012/IMG_3454.jpeg",
      name: "PLUSTICBOY",
      nickname: "plusticboy",
      bg_color_about: "bg-gradient-to-tl from-gray-800 to-gray-400",
      about_1:
        "Plusticboy is a virtual male model representing Asia produced by Aww Inc. He is also the brother of imma. With his age and name unrevealed, he has a cool and mysterious personality which makes it hard for people to read his mind- a contrast to his bright and curious sister. Aside from listening to music and going to concerts, you can find him and following his sister to art museums or doing some occasional modeling.",
      about_2:
        "‍Plusticboy has appeared on the cover of the “Uniform magazine”, an international newspaper and media outlet in China, modeled in SLY × PUMA collection campaign, and has been featured on Rakuten Fashion Week Tokyo 2020’s key visual.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546098839302244/Screenshot_2023-06-18_210314.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546031772389506/Screenshot_2023-06-18_210441.png",
      followers: "49K",
      text_followers:
        "virtual human. In the fake plastic earth. 🧠: @aww.tokyo 🔥: @imma.gram 💊: @ria_ria_tokyo",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/plusticboy/",
      facebook: "",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546098352771145/Screenshot_2023-06-18_210303.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546098038190161/Screenshot_2023-06-18_210253.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546097731997696/Screenshot_2023-06-18_210241.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546097312575619/Screenshot_2023-06-18_210232.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546069340770324/Screenshot_2023-06-18_210418.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546068279603210/Screenshot_2023-06-18_210338.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546032439283763/Screenshot_2023-06-18_210519.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546032120508476/Screenshot_2023-06-18_210506.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120546068984242176/Screenshot_2023-06-18_210356.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1118839426114650134/Ethan_Pic_03.png",
      name: "Ethan Lee",
      nickname: "ethan_lee",
      bg_color_about: "bg-gradient-to-tl from-gray-800 to-blue-300",
      about_1:
        "Meet Ethan Lee, a 22-years-old man with a passion for art and design. Born on March 3, 2001, in Bangkok, Thailand, Ethan is of mixed heritage, with a Thai mother and a Chinese father. He has grown up in a creative and vibrant environment, with a love for art and design from a young age.",
      about_2:
        "At 5'9' tall and weighing 150 pounds, Ethan has a lean and artistic build. He loves to stay inspired by exploring the city's galleries and museums, as well as traveling to other countries to experience different cultures and artistic traditions.",
      about_img:
        "https://bafkreigiouah4cz37dj3hbgpsqgntsgbvnhbk256bqo3ru3mc37lmg2uei.ipfs.nftstorage.link/",
      age: "Age : 22 years old",
      blood_type: "Blood Type : O",
      height: "Hight : 180 cm. , Weight : 68 kg.",
      Chest: "Chest : 44” , Waist : 30” , Hips : 39”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089199046687/00014-3335510175.png",
      followers: "50K",
      text_followers: "Ethan Lee 💖🎶🌈☔️🍕🍷⚽️✈️🎡📷",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/iethan.lee/",
      facebook: "",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1118838780330266664/Ethan_Pic_01.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112669090725773373/00082-2325294244.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112669088897040455/00009-3335510173.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112669090146942986/00050-2718298115.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089199046687/00014-3335510175.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089882718239/00048-2890323437.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089547157524/00026-4172047435.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112669089882718239/00048-2890323437.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1112669090725773373/00082-2325294244.png",
      ],
      contract_address: "0x88fbac774F366ae9B70c51a95AA2C57Dc9f16DAf",
      token_id: "1",
      ipfs: "https://bafkreig25u3yq2aiozrwa3nnlwkoka7qnlvlvkurwwyr2xdargdnxlx5oe.ipfs.nftstorage.link/",
      token_standard: "ERC-721",
      chain: "BEP-20",
      chain_id: "1 (0x1)",
      coin_name: "Ethan Lee ( ethan_lee )",
      description_nft: "Ethan Lee verify by VirtualKOL.com",
    },

    {
      img: "https://media.discordapp.net/attachments/1080058777971212370/1120551934227644536/Screenshot_2023-06-18_212338.png?width=990&height=1236",
      name: "THEO",
      nickname: "theo",
      bg_color_about: "bg-gradient-to-tl from-gray-800 to-gray-400",
      about_1:
        "Theo is a half-Brazilian half-Korean virtual influencer who lives in Seol. He was created by VHP, a start-up founded by five students at Sogang University, last October.",
      about_2: "",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551982860611654/Screenshot_2023-06-18_212012.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551969698893874/Screenshot_2023-06-18_212118.png",
      followers: "10.2K",
      text_followers:
        "Theo 테오 he/him Digital creator 50% 🇧🇷, 50% 🇰🇷, 100% virtual 🤖 📹 Tiktok: theo.rises (+49K)",
      twitter: "https://twitter.com/theo_rises",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/theo.rises/",
      facebook: "",
      tiktok: "https://www.tiktok.com/@theo.rises?lang=en",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551969698893874/Screenshot_2023-06-18_212118.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551969128456273/Screenshot_2023-06-18_212107.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551968805503066/Screenshot_2023-06-18_212043.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551933841784894/Screenshot_2023-06-18_212303.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551933506244708/Screenshot_2023-06-18_212215.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551933111959653/Screenshot_2023-06-18_212159.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551900341862581/Screenshot_2023-06-18_212904.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551899993739285/Screenshot_2023-06-18_212819.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120551899540766761/Screenshot_2023-06-18_212545.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },

    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120554762123874345/Screenshot_2023-06-18_214306.png",
      name: "GEICO GECKO",
      nickname: "geico_gecko",
      bg_color_about: "bg-gradient-to-tl from-green-700 to-gray-400",
      about_1:
        "The GEICO Gecko, the most uncommon of creatures, is uniquely adapted to help people save money on car insurance. The GEICO Gecko quickly became a force to be reckoned with in the advertising world and was voted America's favorite advertising icon in 2005. ",
      about_2:
        "This small green friend has traveled the country spreading the good news about GEICO and has captivated audiences of all ages.The idea for the Gecko grew from a creative session at GEICO's ad agency, the Martin Agency in Richmond, Virginia. The name 'GEICO' was often mispronounced 'Gecko,' and as the brainstorming began, a quick doodle of a gecko appeared. Successful ad campaigns from the past have proven animals create a strong connection between customers and companies.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554839605260308/Screenshot_2023-06-18_213646.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554761452793939/Screenshot_2023-06-18_214255.png",
      followers: "32.1K",
      text_followers:
        "Personal blog Official account of a tiny lizard with a penchant for the finer things in life and spreading the news about savings with @geico. 🚘🏍🚤🏠💰🦎",
      twitter: "https://twitter.com/thegeicogecko/",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/thegeicogecko/",
      facebook: "https://www.facebook.com/thegeicogecko",
      tiktok: "https://www.tiktok.com/@thegeicogecko",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554838971924542/Screenshot_2023-06-18_213625.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554819556491314/Screenshot_2023-06-18_214148.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554819065761792/Screenshot_2023-06-18_214135.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554818629550121/Screenshot_2023-06-18_214122.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554818155597915/Screenshot_2023-06-18_214106.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554790552879154/Screenshot_2023-06-18_214207.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554790880026725/Screenshot_2023-06-18_214215.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554760714588310/Screenshot_2023-06-18_214233.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120554761045934182/Screenshot_2023-06-18_214244.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },

    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120558283929964635/Screenshot_2023-06-18_215439.png",
      name: "ROZY OH",
      nickname: "rozy_oh",
      bg_color_about: "bg-gradient-to-tl from-yellow-600 to-gray-400",
      about_1:
        "Oh Rozy (which also means 'one and only' in Korean) is South Korea's very first virtual influencer, who's famous for her toned body and attractive facial features with an asian heritage created by Sidus Studio-X.Communicating daily lives nonstop with her followers through social media uploads, comments or even magazine photoshoots is Rozy's favorite thing to do just like other human influencers.",
      about_2:
        "She is loved by Gen Z known as 'GamSeong JangIn' (Korean phrase meaning 'expression expert') due to her amazing facial expressions and postures that fits various concepts accordingly.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558283594403931/Screenshot_2023-06-18_215419.png",
      age: "Age : 21 years old",
      blood_type: "Blood Type : -",
      height: "Hight : 173 cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558284353568828/Screenshot_2023-06-18_215452.png",
      followers: "154K",
      text_followers:
        "로지_버추얼 인플루언서 OH!_ROZY 🌈 ᴋᴏʀᴇᴀ's ғɪʀsᴛ ᴠɪʀᴛᴜᴀʟ ɪɴғʟᴜᴇɴᴄᴇʀ 🌟 🌍 ɪ ᴀᴍ ᴛʜᴇ ᴏɴʟʏ ᴏɴᴇ, ɪ ᴄᴏᴜʟᴅ ʙᴇ ᴇᴠᴇʀʏᴏɴᴇ ✈ 🎵 💌 𝐜𝐨𝐧𝐭𝐚𝐜𝐭@𝐥𝐨𝐜𝐮𝐬-𝐱.𝐜𝐨𝐦",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/rozy.gram/",
      facebook: "",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558313856307331/Screenshot_2023-06-18_215319.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558313390735420/Screenshot_2023-06-18_215251.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558314225410068/Screenshot_2023-06-18_215346.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558314535780392/Screenshot_2023-06-18_215359.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558341454827540/Screenshot_2023-06-18_215147.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558341744230430/Screenshot_2023-06-18_215204.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558342117535844/Screenshot_2023-06-18_215222.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558342411128984/Screenshot_2023-06-18_215237.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558369355337748/Screenshot_2023-06-18_215125.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120558369665720330/Screenshot_2023-06-18_215134.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120560860981624873/Screenshot_2023-06-18_220129.png",
      name: "katii",
      nickname: "katii",
      bg_color_about: "bg-gradient-to-tl from-green-500 to-gray-400",
      about_1: "Katii (pronounced Katie) is a virtual model from Thailand. ",
      about_2: "She recently starred in a campaign for Calvin Klein Thailand",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560874961248306/Screenshot_2023-06-18_220035.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560860599951371/Screenshot_2023-06-18_220117.png",
      followers: "61.9",
      text_followers:
        "กะทิ | Katii | Katie 👩🏻✨ Learning to be all I can be. 💼💭 For work pls DM ",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/katii.katie/?hl=en",
      facebook: "https://www.facebook.com/KatiiKatieOfficial",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560860201488514/Screenshot_2023-06-18_220109.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560859920466001/Screenshot_2023-06-18_220100.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560828983291924/Screenshot_2023-06-18_220204.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560828366716968/Screenshot_2023-06-18_220156.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560828006010901/Screenshot_2023-06-18_220148.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560827582394470/Screenshot_2023-06-18_220138.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560795235917834/Screenshot_2023-06-18_220238.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560794904559616/Screenshot_2023-06-18_220227.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120560794585813113/Screenshot_2023-06-18_220219.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120562969923817534/featured-inside-140-1600x900.webp",
      name: "KYRA",
      nickname: "kyra",
      bg_color_about: "bg-gradient-to-tl from-blue-500 to-gray-400",
      about_1: "Kyra is a virtual model from Mumbai, India.",
      about_2: "",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562691631751239/Screenshot_2023-06-18_220447.png",
      age: "Age : 22 years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562676645494794/Screenshot_2023-06-18_220539.png",
      followers: "253K",
      text_followers:
        "India's first Meta-Influencer She/Her 📍 Mumbai Dream chaser, model and traveller 📩 Kyra@futrstudios.in",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/kyraonig/",
      facebook: "",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562675915694080/Screenshot_2023-06-18_220517.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562675588550687/Screenshot_2023-06-18_220506.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562651429351494/Screenshot_2023-06-18_220611.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562651097989160/Screenshot_2023-06-18_220602.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562650821181450/Screenshot_2023-06-18_220554.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562650540159066/Screenshot_2023-06-18_220546.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562623751135362/Screenshot_2023-06-18_220644.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562623407198251/Screenshot_2023-06-18_220635.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120562622950023188/Screenshot_2023-06-18_220625.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120564887526068274/Screenshot_2023-06-18_221700.png",
      name: "NEFELE",
      nickname: "nefele",
      bg_color_about: "bg-gradient-to-tl from-gray-900 to-gray-400",
      about_1: "Nefele is a digitally imperfect model from Italy with vitiligo",
      about_2:
        " a skin condition which causes people to lose pigmentation on certain parts of their body.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564925148975104/Screenshot_2023-06-18_221633.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564941326401546/Screenshot_2023-06-18_221514.png",
      followers: "3,317",
      text_followers:
        "The First Imperfect Virtual Woman 📍Metaverso 🫧L’Imperfezione è Bellezza ❌TEDx Speaker ✨Virtual Human",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/nefele.verse/",
      facebook: "https://www.facebook.com/people/NefeleVerse/100076125860559/",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564925148975104/Screenshot_2023-06-18_221633.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564924859564032/Screenshot_2023-06-18_221618.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564924549173268/Screenshot_2023-06-18_221606.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564924268171414/Screenshot_2023-06-18_221551.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564924003926066/Screenshot_2023-06-18_221541.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564888436224021/Screenshot_2023-06-18_221724.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564858178506762/Screenshot_2023-06-18_221842.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564887937093632/Screenshot_2023-06-18_221715.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564886989180958/Screenshot_2023-06-18_221647.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564859461976104/Screenshot_2023-06-18_221908.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564859076104202/Screenshot_2023-06-18_221851.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120564857851363439/Screenshot_2023-06-18_221832.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120579615019241553/Screenshot_2023-06-18_223518.png",
      name: "RINA",
      nickname: "rina",
      bg_color_about: "bg-gradient-to-tl from-yellow-700 to-gray-400",
      about_1:
        "Rina is a virtual influencer from South Korea created by Metaverse Entertainment.",
      about_2: "",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579647197941830/Screenshot_2023-06-18_223451.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579615447068722/Screenshot_2023-06-18_223526.png",
      followers: "33K",
      text_followers:
        "RINA - 리나 𝐼 𝑐𝑎𝑛 𝑏𝑒 𝑎𝑛𝑦𝑡ℎ𝑖𝑛𝑔 𝐼 𝑤𝑎𝑛𝑡 𝑡𝑜 𝑏𝑒 Your favorite digital girl from Seoul, Korea META ENT | SUBLIME / rina@meta-ent.com",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/rina.8k/?hl=en",
      facebook: "",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579647533490296/Screenshot_2023-06-18_223502.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579615887462510/Screenshot_2023-06-18_223540.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579614507544668/Screenshot_2023-06-18_223511.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579588523819038/Screenshot_2023-06-18_223619.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579588121169980/Screenshot_2023-06-18_223606.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579560128397312/Screenshot_2023-06-18_223628.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579587525582899/Screenshot_2023-06-18_223548.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579587810787338/Screenshot_2023-06-18_223556.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579561340551188/Screenshot_2023-06-18_223651.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579560963047464/Screenshot_2023-06-18_223642.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120579560610734090/Screenshot_2023-06-18_223634.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120581456029294704/Screenshot_2023-06-18_235224.png",
      name: "KAMI",
      nickname: "kami",
      bg_color_about: "bg-gradient-to-tl from-purple-500 to-gray-400",
      about_1:
        "Kami, short for Kamilah, is the first virtual influencer with Down syndrome. She was created by a partnership between Down Syndrome International and virtual modeling agency The Diigitals, creators of Shudu. One hundred real women with Down syndrome volunteered their faces, expressions, and mannerisms to provide the basis for Kami.",
      about_2:
        " A collective of women with Down syndrome called 'Kami Contributors' are the ones who decide what Kami wears, where she goes, and writes her captions for social media.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581455433711687/Screenshot_2023-06-18_235142.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581430813147216/Screenshot_2023-06-18_235314.png",
      followers: "3,110",
      text_followers:
        "🤖A virtual girl with an extra chromosome ✨ Created by 💯 women with Down syndrome Making the digital world more inclusive💙💛",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/itskamisworld/",
      facebook: "",
      tiktok: "",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581455169462292/Screenshot_2023-06-18_235123.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581454884253776/Screenshot_2023-06-18_235106.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581432365035540/Screenshot_2023-06-18_235347.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581431949787236/Screenshot_2023-06-18_235324.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581430020407356/Screenshot_2023-06-18_235300.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581398768652318/Screenshot_2023-06-18_235815.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581398475059320/Screenshot_2023-06-18_235805.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581398131122276/Screenshot_2023-06-18_235447.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120581455781834843/Screenshot_2023-06-18_235209.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
    {
      img: "https://cdn.discordapp.com/attachments/1080058777971212370/1120583096375447572/Screenshot_2023-06-19_000551.png",
      name: "ANELIE GODAR",
      nickname: "anelie_godar",
      bg_color_about: "bg-gradient-to-tl from-yellow-700 to-gray-400",
      about_1:
        "Anelie is a very unusual influencer. She lives in Moscow and had a difficult childhood as a robot. Anelie will not be quiet about people sliding disrespectful pictures into her DMs. She is ready to call out bullies that talk about her body in a negative way and she will defend herself from harassment. In her own effortless style",
      about_2:
        " Anelie creates makeup tutorials using ice cream, smokes while sick at the hospital and sings in a fake punk band. But don’t be scared! Anelie will always provide motivational quotes for self respect and love. She also is a vocal advocate for animal rights, loves food and creates crazy campaigns to connect with followers.",
      about_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583044349313154/Screenshot_2023-06-19_000803.png",
      age: "Age : - years old",
      blood_type: "Blood Type : -",
      height: "Hight : - cm. , Weight : - kg.",
      Chest: "Chest : -” , Waist : -” , Hips : -”",
      followers_img:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583072476307456/Screenshot_2023-06-19_000727.png",
      followers: "1,550",
      text_followers:
        "ᴀɴᴇʟɪᴇ ɢᴏᴅᴀʀ 🧬🩸💡💎🧩🐁 🖤𝔫𝔬 𝔣𝔦𝔩𝔱𝔢𝔯𝔰, 𝔫𝔬 𝔭𝔥𝔬𝔱𝔬𝔰𝔥𝔬𝔭, 𝔫𝔬 𝔩𝔦𝔣𝔢🪦 💗Неживая и недовольная девчонка из России! 🤍Collab w. digital avatar: aneliegodar@gmail.com",
      twitter: "",
      reddit: "",
      youtube: "",
      instagram: "https://www.instagram.com/aneliegodar/",
      facebook: "",
      tiktok: "https://www.tiktok.com/@aneliegodar",
      img_show:
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583096731979836/Screenshot_2023-06-19_000601.png",
      img_list_1: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583095926661190/Screenshot_2023-06-19_000538.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583071872335955/Screenshot_2023-06-19_000634.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583071452897320/Screenshot_2023-06-19_000622.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583044693233705/Screenshot_2023-06-19_000817.png",
      ],
      img_list_2: [
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583044349313154/Screenshot_2023-06-19_000803.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583043992801300/Screenshot_2023-06-19_000749.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583011222704138/Screenshot_2023-06-19_000853.png",
        "https://cdn.discordapp.com/attachments/1080058777971212370/1120583011721814086/Screenshot_2023-06-19_000920.png",
      ],
      contract_address: "",
      token_id: "",
      ipfs: "",
      token_standard: "",
      chain: "",
      chain_id: "",
      coin_name: "",
      description_nft: "",
    },
  ],
};

const getters = {
  getModels: (state) => state.models,
};

const actions = {
  setModels({ commit }, models) {
    localStorage.setItem("models", JSON.stringify(models));
    commit("setModels", models);
  },
};

const mutations = {
  setModels: (state, station) => (state.station = station),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
