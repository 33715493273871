import { createRouter, createWebHistory } from "vue-router";
import nativeToast from "native-toast";
import store from "../store";

const routes = [
  // {
  //   path: "/funds",
  //   component: () => import("../views/auth/funds"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/earn",
  //   component: () => import("../views/public/earn.vue"),
  // },
  // {
  //   path: "/search/:sort",
  //   component: () => import("../views/public/search.vue"),
  // },
  // {
  //   path: "/search",
  //   component: () => import("../views/public/search.vue"),
  // },
  // {
  //   path: "/account",
  //   component: () => import("../views/auth/account"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/admin",
  //   component: () => import("../views/auth/admin/AdminList.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/stakeLP/:contract_address",
  //   component: () => import("../views/auth/StakeLP.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/balance/:currency",
  //   component: () => import("../views/auth/balance.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/kyc/:address",
  //   component: () => import("../views/auth/accountKYC.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/selectkyc/:address",
  //   component: () => import("../views/auth/selectKYC.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/addphone/:address",
  //   component: () => import("../views/auth/addPhone.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/casino",
  //   component: () => import("../views/auth/casino"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/one_hash",
  //   component: () => import("../views/auth/bet/one_hash.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/sell",
  //   component: () => import("../views/auth/sell/index.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/publicFree",
  //   component: () => import("../views/auth/sell/publicFree.vue"),
  // },

  // {
  //   path: "/bet",
  //   component: () => import("../views/auth/bet/bet.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/two_hash",
  //   component: () => import("../views/auth/bet/two_hash.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/last_hash",
  //   component: () => import("../views/auth/bet/last_hash.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: "/collections",
  //   component: () => import("../views/auth/myCollections.vue"),
  //   meta: { requiresAuth: true },
  // },

  {
    path: "/",
    component: () => import("../views/public/home.vue"),
  },
  {
    path: "/model_nisa",
    component: () => import("../views/public/model.vue"),
  },
  {
    path: "/models/:model",
    component: () => import("../views/public/models/models.vue"),
  },
  {
    path: "/miquela_sousa",
    component: () => import("../views/public/models/miquela_sousa.vue"),
  },
  {
    path: "/model_ethan",
    component: () => import("../views/public/model_ethan.vue"),
  },
  {
    path: "/influencerrs",
    component: () => import("../views/public/influencerrs.vue"),
  },
  {
    path: "/news",
    component: () => import("../views/public/news.vue"),
  },
  {
    path: "/interviews",
    component: () => import("../views/public/interviews.vue"),
  },
  {
    path: "/contact",
    component: () => import("../views/public/contact.vue"),
  },
  {
    path: "/news/1",
    component: () => import("../views/public/news/news_1.vue"),
  },
  {
    path: "/news/2",
    component: () => import("../views/public/news/news_2.vue"),
  },
  {
    path: "/news/3",
    component: () => import("../views/public/news/news_3.vue"),
  },
  {
    path: "/news/4",
    component: () => import("../views/public/news/news_4.vue"),
  },
  // {
  //   path: "/prompt/:_id",
  //   component: () => import("../views/public/prompt_detail.vue"),
  // },
  // {
  //   path: "/promptFree/:_id",
  //   component: () => import("../views/public/prompt_free_detail.vue"),
  // },
  // {
  //   path: "/promptAdmin/:_id",
  //   component: () => import("../views/auth/admin/AdminDetail.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/pay/:_id",
  //   component: () => import("../views/public/pay.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/refer",
  //   component: () => import("../views/public/refer.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/r/:ref",
  //   component: () => import("../views/public/home.vue"),
  // },
  // {
  //   path: "/model/:model",
  //   component: () => import("../views/public/detail.vue"),
  // },
  // {
  //   path: "/product/:id",
  //   component: () => import("../views/public/product_detail.vue"),
  // },

  // {
  //   path: "/booking/:model",
  //   component: () => import("../views/public/booking.vue"),
  // },
  // {
  //   path: "/:pathMatch(.*)*",
  //   component: () => import("../views/public/pagenotfound.vue"),
  // },
  // {
  //   path: "/user/:address",
  //   component: () => import("../views/public/user.vue"),
  // },
  // {
  //   path: "/txrelation/:tx_id",
  //   component: () => import("../views/public/txrelation.vue"),
  // },
  // {
  //   path: "/transactions/:address",
  //   component: () => import("../views/public/transactions.vue"),
  // },
  // {
  //   path: "/deposit/:address",
  //   component: () => import("../views/public/deposit.vue"),
  // },
  {
    path: "/faq",
    component: () => import("../views/public/faq.vue"),
  },
  {
    path: "/privacy",
    component: () => import("../views/public/Privacy.vue"),
  },
  {
    path: "/TermOfService",
    component: () => import("../views/public/TermOfService.vue"),
  },

  // {
  //   path: "/withdraw/:address",
  //   component: () => import("../views/public/withdraw.vue"),
  // },

  // {
  //   path: "/ranking/:currency",
  //   component: () => import("../views/public/ranking.vue"),
  // },
  // {
  //   path: "/lastesttransactions",
  //   component: () => import("../views/public/lastesttransactions.vue"),
  // },
  // {
  //   path: "/alldeposit",
  //   component: () => import("../views/public/alldeposit.vue"),
  // },
  // {
  //   path: "/allwithdrawal",
  //   component: () => import("../views/public/allwithdrawal.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = store.getters.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("/");
    nativeToast({
      message: "Please Connect Metamask.",
      position: "top",
      timeout: 3000,
      type: "error",
    });
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
