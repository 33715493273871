<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | virtual` : `AIvirtual`
    }}</template>
  </metainfo>

  <div id="app">
    <router-view class="font-sans" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import nativeToast from "native-toast";

export default {
  name: "App",
  data() {
    return {};
  },

  components: {},
  sockets: {
    connect() {
      this.setConnect(true);
    },
    disconnect() {
      this.setConnect(false);
    },
  },
  computed: mapGetters(["isAuthenticated", "getUser"]),
  methods: {
    ...mapActions([
      "fetchAssets",
      "fetchBalance",
      "setConnect",
      "setOnlines",
      "setBalance",
      "setMarket",
      "setBlock",
      "fetchMarket",
      "setInvest",
      "UsersConnected",
      "UsersDisconnected",
      "logout",
    ]),
    onlineUsers() {
      this.sockets.subscribe("users.joined", (data) => {
        this.UsersConnected(data);
      });

      this.sockets.subscribe("users.left", (data) => {
        this.UsersDisconnected(data);
      });

      this.sockets.subscribe("announce", (data) => {
        nativeToast({
          message: data.message,
          position: data.position,
          timeout: data.timeout,
          type: data.type,
        });
      });
    },
    exchange() {
      this.sockets.subscribe("market", (data) => {
        this.setMarket(data);
      });
    },
    investbox() {
      this.sockets.subscribe("investbox", (data) => {
        this.setInvest(data);
      });
    },
    block() {
      this.sockets.subscribe("blocks", (data) => {
        this.setBlock(data);
      });
    },
  },
  created() {},
};
</script>
